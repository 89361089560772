// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partials-catarinas-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/catarinas.js" /* webpackChunkName: "component---src-pages-partials-catarinas-js" */),
  "component---src-pages-partials-contato-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/contato.js" /* webpackChunkName: "component---src-pages-partials-contato-js" */),
  "component---src-pages-partials-content-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/content.js" /* webpackChunkName: "component---src-pages-partials-content-js" */),
  "component---src-pages-partials-cronograma-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/cronograma.js" /* webpackChunkName: "component---src-pages-partials-cronograma-js" */),
  "component---src-pages-partials-depoimentos-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/depoimentos.js" /* webpackChunkName: "component---src-pages-partials-depoimentos-js" */),
  "component---src-pages-partials-hero-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/hero.js" /* webpackChunkName: "component---src-pages-partials-hero-js" */),
  "component---src-pages-partials-jornada-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/jornada.js" /* webpackChunkName: "component---src-pages-partials-jornada-js" */),
  "component---src-pages-partials-maturidade-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/maturidade.js" /* webpackChunkName: "component---src-pages-partials-maturidade-js" */),
  "component---src-pages-partials-mindset-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/mindset.js" /* webpackChunkName: "component---src-pages-partials-mindset-js" */),
  "component---src-pages-partials-newsletter-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/newsletter.js" /* webpackChunkName: "component---src-pages-partials-newsletter-js" */),
  "component---src-pages-partials-precisa-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/precisa.js" /* webpackChunkName: "component---src-pages-partials-precisa-js" */),
  "component---src-pages-partials-programa-js": () => import("/Users/cspilhere/Projects/supernova/src/pages/partials/programa.js" /* webpackChunkName: "component---src-pages-partials-programa-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/cspilhere/Projects/supernova/.cache/data.json")

